import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import Impact from './goal/Impact'
import Goal   from './goal/Goal'
import Top    from './donations/Top'
import All    from './donations/All'

import { unique } from './donations/useInfiniteOrders'
import safeJSON from 'src/utils/safeJSON'
import mountIfExists from 'src/utils/mountIfExists'

const INTERVAL = 30000

const App = props => {
  const [ flp, setFlp ] = useState(props.fundraiserLandingPage)
  const [ impactWidget, setImpactWidget ] = useState(props.impactWidget)
  const [ orders, setOrders ] = useState(props.orders)
  const [ topOrders, setTopOrders ] = useState(props.top_orders)
  const [ pageHidden, setPageHidden ] = useState(document.hidden)
  const [ needsRefresh, setNeedsRefresh ] = useState(false)

  const fetchRecentDonations = order => {
    setNeedsRefresh(false)
    fetch(location.pathname, { headers: { Accept: 'application/json'} })
    .then(response => response.json()).then(data => {
      setFlp(data.fundraiser_landing_page)
      setImpactWidget(data.impact_widget)
      setOrders(
        order
        ? unique(data.orders, [ order ]).concat(data.orders).sort((a, b) => b.id - a.id)
        : data.orders
      )
      setTopOrders(data.top_orders)
    })
  }

  useEffect(() => {
    if (pageHidden) return

    if (needsRefresh) fetchRecentDonations()
    const interval = setInterval(fetchRecentDonations, INTERVAL)
    return () => clearInterval(interval)
  }, [ pageHidden, needsRefresh ])

  useEffect(() => {
    if (!pageHidden) return

    const timeout = setTimeout(() => setNeedsRefresh(true), INTERVAL)
    return () => clearTimeout(timeout)
  }, [ pageHidden ])

  useEffect(() => {
    const handleVisibilityChange = () => setPageHidden(document.hidden)
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  useEffect(() => {
    const handleMessage = e => {
      if (e.origin != location.origin) return

      const data = safeJSON(e.data)
      switch (data.message) {
        case 'DonateCompleted':
          return fetchRecentDonations(data.data.order)
      }
    }

    addEventListener('message', handleMessage)
    return () => removeEventListener('message', handleMessage)
  }, [])

  return (
    <div>
      {
        Array.prototype.map.call(
          document.querySelectorAll('.GoalRoot'),
          (goalRoot, i) => {
            const placeholder = goalRoot.querySelector('.placeholder')
            placeholder && goalRoot.removeChild(placeholder)

            return createPortal(
              impactWidget
              ? <Impact key={i}
                  {...impactWidget}
                  iconFile={props.iconFile}
                  goal={flp.goal} // replace with goal on impact widget later
                />
              : <Goal key={i} {...flp} />,
              goalRoot
            )
          }
        )
      }
      {
        mountIfExists('RecentDonationsRoot', () =>
          <All
            orders={orders}
            more_orders_available={props.more_orders_available}
            organizer={props.organizer}
            logged_in={props.logged_in}
          />
        )
      }
      {
        mountIfExists('TopDonationsRoot', () =>
          <Top
            topOrders={topOrders}
            organizer={props.organizer}
            logged_in={props.logged_in}
          />
        )
      }
    </div>
  )
}

export default App
