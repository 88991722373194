import React from 'react'

import Donations from './Donations'

const Top = ({ topOrders, organizer, logged_in }) =>
  <>
    <Donations orders={topOrders} organizer={organizer} logged_in={logged_in} />
    {
      topOrders.length == 10 &&
      <div className="border-top fst-italic px-3 px-sm-4 py-2">
        <small>*Displaying the top ten donations</small>
      </div>
    }
  </>

export default Top
