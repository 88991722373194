import { useState, useEffect, useRef } from 'react'

export const unique = (current, added) => {
  const ids = current.map(o => o.id)
  return added.filter(o => !ids.includes(o.id))
}

const useInfiniteOrders = ({
  orders, more_orders_available, anonymizedOrders,
  feedUrl = location.pathname
}) => {
  const [ visible, setVisible ] = useState({ top: false, bottom: false })
  const [ loading, setLoading ] = useState(false)
  // Combined orders/more state object to avoid race conditions on update
  const [ state, setState ] = useState({ orders, more_orders_available })

  const top = useRef()
  const bottom = useRef()

  const fetchNewDonations = async () => {
    const params = new URLSearchParams({ first_id: state.orders[0]?.id || '' })
    const url = [ feedUrl, params ].join(feedUrl.includes('?') ? '&' : '?')
    const response = await fetch(url, { headers: { Accept: 'application/json' } })
    const { orders } = await response.json()

    setState(state => ({
      ...state,
      orders: unique(state.orders, orders).concat(state.orders),
      newOrdersFetched: true,
    }))
  }

  useEffect(() => {
    if (!visible.top || !orders.length) return

    const propsFirstOrderId = orders[0].id
    const stateFirstOrderId = state.orders[0]?.id || 0
    if (propsFirstOrderId <= stateFirstOrderId) return

    const propsLastOrderId = orders.at(-1).id
    if (state.orders.find(o => o.id == propsLastOrderId))
      setState(state => ({
        ...state,
        orders: unique(state.orders, orders).concat(state.orders),
        newOrdersFetched: true,
      }))
    else
      fetchNewDonations()
  }, [ orders, state.orders, visible.top ])

  const fetchDonations = async () => {
    setLoading(true)
    try {
      const params = new URLSearchParams({ last_id: state.orders.at(-1).id })
      const url = [ feedUrl, params ].join(feedUrl.includes('?') ? '&' : '?')
      const response = await fetch(url, { headers: { Accept: 'application/json' } })
      const { orders, more_orders_available } = await response.json()

      setState(state => ({
        orders: state.orders.concat(unique(state.orders, orders)),
        more_orders_available,
      }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (anonymizedOrders?.length) {
      setState(state => ({
        ...state,
        orders: state.orders.map(order =>
          anonymizedOrders.map(o => o.id).includes(order.id)
          ? { ...order, donor_name: 'Anonymous', anonymized: true }
          : order
        )
      }))
    }
  }, [ anonymizedOrders ])

  useEffect(() => {
    if (visible.bottom && state.more_orders_available && !loading)
      fetchDonations()
  }, [ visible.bottom, state.more_orders_available, loading ])

  useEffect(() => {
    const handleIntersection = entries =>
      entries.forEach(entry => {
        const ref = (() => {
          switch (entry.target) {
            case top.current: return 'top'
            case bottom.current: return 'bottom'
          }
        })()
        setVisible(visible => ({ ...visible, [ref]: entry.isIntersecting }))
      })

    const intersectionObserver = new IntersectionObserver(handleIntersection)
    intersectionObserver.observe(top.current)
    intersectionObserver.observe(bottom.current)
    return () => intersectionObserver.disconnect()
  }, [])

  return {
    newOrdersFetched: state.newOrdersFetched,
    orders: state.orders,
    loading,
    refs: { top, bottom },
  }
}

export default useInfiniteOrders
