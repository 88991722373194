import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TimeAgo from 'react-timeago'
import defaultFormatter from 'react-timeago/lib/defaultFormatter'

import ProfilePicture from 'src/components/ProfilePicture'
import Comment from './Comment'
import CommentField from './CommentField'
import donationAmount from './donationAmount'
import safeJSON from 'src/utils/safeJSON'
import { capitalize } from 'src/utils/inflections'

const Donation = ({ order, organizer, logged_in, visibleCommentField, toggleVisibleCommentField }) => {
  const [ comments, setComments ] = useState(order.comments)
  const addComment = comment =>
    setComments(comments => comments.concat(comment))

  useEffect(() => {
    const handleMessage = e => {
      if (e.origin != location.origin) return

      const data = safeJSON(e.data)
      switch (data.message) {
        case 'CommentAddedToDonation':
          if (data.data.order_id == order.id)
            return addComment(data.data)
      }
    }

    addEventListener('message', handleMessage)
    return () => removeEventListener('message', handleMessage)
  }, [])

  return (
    <div className="d-flex flex-column py-4 row-gap-3">
      <div className="d-flex flex-wrap align-items-center column-gap-3">
        <div className="d-flex align-items-center gap-2">
          <div>{/* wrap to prevent flex from squishing the initials */}
            <div className="initials position-relative" aria-hidden="true">
              {
                (order.has_business_name || order.anonymized) ?
                <FontAwesomeIcon icon="heart"/> :
                order.donor_initials
              }
              <ProfilePicture source={order.profile_picture} />
            </div>
          </div>
          <div className="h6 fw-normal">
            <b>{order.donor_name}</b>
            <span className="text-body-secondary"> donated </span>
            <span className="text-nowrap">
              <b>{donationAmount(order)}</b>
              {
                order.currency.iso_code != 'USD' ?
                <small className="text-body-secondary"> {order.currency.iso_code}</small>
                : null
              }
            </span>
            <br />
            <small className="me-n2">
              <span className="me-2">
                <TimeAgo
                  date={order.created_at}
                  formatter={(value, unit, suffix) =>
                    unit == 'second'
                    ? 'less than a minute ago'
                    : defaultFormatter(value, unit, suffix)
                  }
                />
                {
                  order.is_pledge &&
                  <span className="text-primary"> (pending)</span>
                }
              </span>
              {
                order.is_match &&
                <span className="me-2 text-primary text-nowrap">
                  Match 🎉
                </span>
              }
            </small>
          </div>
        </div>

        <div className="ms-auto position-relative">
          {
            !logged_in &&
            <a
              href={`${location.pathname}/signin`}
              className="btn btn-sm btn-light btn-check-special position-absolute end-0 reaction-signin"
              style={{ minWidth: '100%' }}
            >Sign In</a>
          }
          <div className="text-nowrap d-flex gap-1">
            {[
              { type: 'clap', emoji: '👏' },
              { type: 'love', emoji: '💜' },
            ].map(({ type, emoji }) => {
              const reactions = order.reactions[type]
              return (
                <button
                  key={type}
                  className={[
                    'btn btn-sm btn-light btn-check-special reaction-button',
                    reactions?.userReacted ? 'active' : '',
                  ].filter(Boolean).join(' ')}
                  title={`${capitalize(type)} reaction`}
                  disabled={!logged_in}
                  data-activity-type="Order"
                  data-activity-id={order.id}
                  data-reaction-type={type}
                  data-reaction-count={reactions?.count || 0}
                >
                  {emoji} <span className="pe-none fw-normal reaction-counter">
                    {reactions?.count || ''}
                  </span>
                </button>
              )
            })}
            {
              organizer.isCurrentUser &&
              <button
                className={[
                  "btn btn-sm btn-light btn-check-special",
                  visibleCommentField == order.uuid && 'active',
                ].filter(Boolean).join(' ')}
                onClick={() => toggleVisibleCommentField(order.uuid)}
                title="Comment"
              >
                <FontAwesomeIcon
                  icon={[ 'regular', 'comment' ]}
                  className="text-primary"
                />
              </button>
            }
          </div>
        </div>
      </div>
      {comments.map(comment =>
        <Comment
          key={comment.uuid}
          comment={comment}
          organizer={organizer}
          visibleCommentField={visibleCommentField}
          toggleVisibleCommentField={toggleVisibleCommentField}
        />
      )}
      {
        visibleCommentField == order.uuid &&
        <CommentField
          order={order}
          visibleCommentField={visibleCommentField}
          toggleVisibleCommentField={toggleVisibleCommentField}
          onCommentCreated={addComment}
        />
      }
    </div>
  )
}

export default Donation
